import React, { useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as Sentry from "@sentry/browser";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";

function VideoForm(props) {
  let history = useHistory();
  // const [video, setVideo] = useState({});
  // const [youtubeId, setYoutubeId] = useState('');
  // const [title, setTitle] = useState('');
  // const [artist, setArtist] = useState('');
  // const [description, setDescription] = useState('');
  const blankVideo = { difficulty: "Beginner", category: "BLUES" };
  const [video, setVideo] = useState(props.video || blankVideo);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const isCreateVideo = props.video ? false : true;
  // useEffect(() => {
  //   if (props.video) {
  //     setVideo(props.video);
  //   }
  // });
  const handleSubmit = async event => {
    event.preventDefault();
    setIsSubmitting(true);
    // send the video in state
    if (isCreateVideo) {
      // create new video
      try {
        const newVideo = await API.graphql(graphqlOperation(mutations.createVideo, { input: video }));
        history.push("/library");
      } catch (err) {
        Sentry.captureException(err);
        // alert(err.errors[0].message);
      }
      // go to video landing page
    } else {
      try {
        const updatedVideo = await API.graphql(graphqlOperation(mutations.updateVideo, { input: video }));
        alert(`Video Updated!`);
        setIsSubmitting(false);
      } catch (err) {
        Sentry.captureException(err);
        // alert(err.errors[0].message);
      }
    }
  };
  const handleDelete = async event => {
    setIsSubmitting(true);
    try {
      const input = { id: video.id };
      await API.graphql(graphqlOperation(mutations.deleteVideo, { input }));
      history.push("/library");
    } catch (err) {
      Sentry.captureException(err);
    }
  };
  const handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setVideo(inputs => ({
      ...inputs,
      [name]: value
    }));
  };
  const levels = ["Beginner", "Experienced"];
  const categories = ["Blues", "Rock", "Acoustic", "Country", "Theory"];
  return (
    <>
      <form id="contact" onSubmit={handleSubmit}>
        <div className="row justify-content-center">
          <div className="col-lg-8 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  Youtube Video ID
                </span>
              </div>
              <input className="form-control" type="text" name="youtubeId" value={video.youtubeId} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  title
                </span>
              </div>
              <input className="form-control" type="text" name="title" value={video.title} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  artist
                </span>
              </div>
              <input className="form-control" type="text" name="artist" value={video.artist} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-8 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  description
                </span>
              </div>
              <textarea className="form-control" name="description" value={video.description} onChange={handleInputChange} />
            </div>
          </div>
        </div>
        <div className="row justify-content-center">
          <div className="col-lg-4 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  level
                </span>
              </div>
              <select className="form-control" id="exampleFormControlSelect1" name="difficulty" onChange={handleInputChange} defaultValue={video.difficulty || "Beginner"}>
                {levels.map(level => {
                  return <option key={level}>{level}</option>;
                })}
              </select>
            </div>
          </div>
          <div className="col-lg-4 form-group">
            <div className="input-group">
              <div className="input-group-prepend">
                <span className="input-group-text" id="inputGroupPrepend">
                  category
                </span>
              </div>
              <select className="form-control" id="exampleFormControlSelect1" defaultValue={video.category} name="category" onChange={handleInputChange}>
                {categories.map(cat => {
                  return (
                    <option value={cat.toUpperCase()} key={cat}>
                      {cat}
                    </option>
                  );
                })}
              </select>
            </div>
          </div>
        </div>
        {/* <div className="row justify-content-center"> */}
        {/*   <div className="col-lg-4 form-group"> */}
        {/*     <div className="input-group"> */}
        {/*       <div className="input-group-prepend"> */}
        {/*         <span className="input-group-text" id="inputGroupPrepend"> */}
        {/*           order */}
        {/*         </span> */}
        {/*       </div> */}
        {/*       <input className="form-control" type="number" name="order" value={video.order} onChange={handleInputChange} /> */}
        {/*     </div> */}
        {/*   </div> */}
        {/* </div> */}
        <div className="row justify-content-center">
          {!isCreateVideo ? (
            <div className="col-lg-4">
              <button type="button" className="btn btn-danger" disabled={isSubmitting} onClick={handleDelete}>
                DELETE
              </button>
            </div>
          ) : null}
          <div className="col-lg-4">
            <button type="submit" className="btn" disabled={isSubmitting}>
              {isCreateVideo ? "CREATE" : "UPDATE"}
            </button>
          </div>
        </div>
      </form>
    </>
  );
}

function EditorPage() {
  function useQuery() {
    return new URLSearchParams(useLocation().search);
  }
  const query = useQuery();
  const id = query.get("id");
  return (
    <>
      <div className="container-fluid" id="topBG">
        <header className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h1>{id ? "EDIT VIDEO" : "CREATE VIDEO"}</h1>
              <hr />
            </div>
          </div>
        </header>
      </div>
      <div className="container-fluid">
        {id ? (
          <Connect query={graphqlOperation(queries.getVideo, { id })}>
            {({ data, loading, errors }) => {
              const video = data.getVideo;
              // if (errors.length > 0) return <h3>{errors[0]}</h3>;
              if (data.getVideo === null) return <h3>Unable to locate video</h3>;
              if (loading || !video) return <h3>Fetching Video</h3>;
              // const videos = listVideos ? listVideos.items : undefined;
              if (!loading) {
                return <VideoForm video={video} loading={loading} />;
              }
            }}
          </Connect>
        ) : (
          <VideoForm />
        )}
      </div>
    </>
  );
}

export default EditorPage;
