import React from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { API, graphqlOperation } from "aws-amplify";
import { useAuth0 } from "../react-auth0-spa";
import * as Sentry from "@sentry/browser";
import * as mutations from "../graphql/mutations";

function ProfilePage() {
  const { loading, userMetaData, logout } = useAuth0();

  const updateUser = async (id, btSubId) => {
    const update = { id, btSubId };
    return await API.graphql(graphqlOperation(mutations.updateUser, { input: update }));
  };

  const cancelSub = async id => {
    const url = `${process.env.REACT_APP_NETLIFY_API}/btcancelsub`;
    const options = {
      url,
      method: "POST",
      data: {
        id
      }
    };
    return await axios(options).then(res => {
      return res;
    });
  };
  const handleCancel = async () => {
    try {
      // cancel it via braintee
      await cancelSub(userMetaData.btSubId);
      // update the user
      await updateUser(userMetaData.id, null);
      // log them out
      logout({
        returnTo: window.location.origin
      });
    } catch (err) {
      alert(`Oops, something went wrong`);
      Sentry.captureException(err);
      // console.error(err);
      return;
    }
  };
  return (
    <>
      <div className="container-fluid" id="topBG">
        <header className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h1>WELCOME USERS LOGIN</h1>
              <hr />
            </div>
          </div>
        </header>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>Payment Status</h2>
              <h4>Current Status:</h4>
              {!loading && userMetaData.activeSub ? (
                <h4 className="text-success">Good To Go</h4>
              ) : (
                <>
                  <h4 className="text-danger">Needs Attention</h4>
                  <Link to="/signup">
                    <button type="button" className="btn">
                      Add Payment Method
                    </button>
                  </Link>
                </>
              )}
            </div>
            <div className="col-md-6">
              <h2>Cancel Subscription</h2>
              <button type="button" className="btn btn-danger" onClick={handleCancel}>
                Cancel Subscription
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ProfilePage;
