import React from "react";
import { useParams } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import { Link } from "react-router-dom";
import * as queries from "../graphql/queries";

const YTVideo = ({ video }) => {
  return (
    <iframe
      title="video"
      width="560"
      height="315"
      src={`https://www.youtube.com/embed/${video}?modestbranding=1`}
      frameBorder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
      allowFullScreen
    />
  );
};

function Video() {
  let { id } = useParams();
  const { user } = useAuth0();
  let isAdmin = false;
  if (user) {
    isAdmin = user.appMetaData.role === "ADMIN";
  }
  return (
    <Connect query={graphqlOperation(queries.getVideo, { id })}>
      {({ data, loading, errors }) => {
        const video = data.getVideo;
        // if (errors.length > 0) return <h3>{errors[0]}</h3>;
        if (data.getVideo === null) return <h3>Unable to locate video</h3>;
        if (loading || !video) return <h3>Fetching Video</h3>;
        // const videos = listVideos ? listVideos.items : undefined;
        if (!loading) {
          const { title, artist, difficulty, description, youtubeId } = video;
          return (
            <div className="container-fluid" id="topBG">
              <header className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <h1>{title.toUpperCase()}</h1>
                    <hr />
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <YTVideo video={youtubeId} />
                  </div>
                </div>
              </header>
              <div className="container">
                <div className="row justify-content-center align-items-center">
                  <div className="col-md-3">
                    <h4 className="songDesc">{title}</h4>
                    <h4 className="songDesc">{artist}</h4>
                    <h4 className="songDesc">{difficulty}</h4>
                  </div>
                  <div className="col-md-7">
                    <div className="songDesc">
                      <h3>LESSON INFO:</h3>
                      <p>{description}</p>
                      <p>{/* <strong>Published:</strong> 07/03/20 */}</p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  {isAdmin && (
                    <div className="col-3">
                      <Link to={`/editor?id=${id}`}>
                        <button type="button" className="btn">
                          EDIT SONG
                        </button>
                      </Link>
                    </div>
                  )}
                  <div className="col-3">
                    <Link to="/library">
                      <button type="button" className="btn">
                        BACK TO SONGS
                      </button>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          );
        }
      }}
    </Connect>
  );
}

export default Video;
