import React, { useState } from "react";
import { Connect } from "aws-amplify-react";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import { API, graphqlOperation } from "aws-amplify";

const AdminForm = ({ data }) => {
  const [meta, setMeta] = useState(data || {});
  // const [carouselVideos, setCarouselVideos] = useState(data.carouselVideos || []);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async e => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      await API.graphql(graphqlOperation(mutations.updateAppMetaData, { input: meta }));
    } catch (err) {
      window.alert("Something went wrong...");
      console.log(err.errors);
      setIsSubmitting(false);
      return;
    }
    // mutation {
    //   updateAppMetaData(
    //     input:{
    //     	id:"bd04eb44-ea91-48bd-9f82-fc48427e5a48",
    //       bannerVideo:"wS7jUpBpo-c",
    //     	carouselVideos:[
    //         "6gIYeNaITuQ",
    //         "6doi1-G1ixQ",
    //         "pMaR4ZkBtPo"
    //     ]
    //     }
    //   ) {
    //     id
    //     bannerVideo
    //     carouselVideos
    //   }
    // }
    window.alert("Updated!");

    setIsSubmitting(false);
    return;
  };
  const handleInputChange = e => {
    const name = e.target.name;
    const value = e.target.value;
    setMeta(inputs => ({
      ...inputs,
      [name]: value
    }));
  };
  const handleCarouselChange = (index, newValue) => {
    const updatedArray = meta.carouselVideos;
    updatedArray[index] = newValue.trim();
    setMeta(inputs => ({
      ...inputs,
      carouselVideos: updatedArray
    }));
  };
  return (
    <>
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="row justify-content-center">
            <div className="col-lg-8 form-group">
              <h4 className="text-left">Banner Video for Home page</h4>
              <div className="input-group">
                <div className="input-group-prepend">
                  <span className="input-group-text" id="inputGroupPrepend">
                    Youtube Video ID
                  </span>
                </div>
                <input className="form-control" type="text" name="bannerVideo" value={meta.bannerVideo} onChange={handleInputChange} />
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-8 form-group">
              <h4 className="text-left">Videos for Carousel</h4>
              {meta.carouselVideos.map((id, index) => {
                return (
                  <>
                    <div className="input-group mb-3" key={id}>
                      <div className="input-group-prepend">
                        <span className="input-group-text" id="inputGroupPrepend">
                          Youtube Video ID
                        </span>
                      </div>
                      <input className="form-control " type="text" name="carouselVideos" value={meta.carouselVideos[index]} onChange={e => handleCarouselChange(index, e.target.value)} />
                    </div>
                  </>
                );
              })}
            </div>
          </div>
          <button type="submit" className="btn" disabled={isSubmitting}>
            Submit
          </button>
        </form>
      </div>
    </>
  );
};

function Admin() {
  return (
    <>
      <div className="container-fluid" id="topBG">
        <header className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h1>UPDATE SITE SETTINGS</h1>
              <hr />
            </div>
          </div>
        </header>
      </div>
      <Connect query={graphqlOperation(queries.listAppMetaDatas)}>
        {({ data, loading, errors }) => {
          if (!loading) {
            return <AdminForm data={data.listAppMetaDatas.items[0]} />;
          }
        }}
      </Connect>
    </>
  );
}

export default Admin;
