import React, { useState } from "react";
import { Link } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import { Connect } from "aws-amplify-react";
import * as queries from "../graphql/queries";
// import * as subscriptions from '../graphql/subscriptions';

const VideoRow = ({ video, isHidden }) => {
  const { artist, difficulty, id, title, youtubeId } = video;
  return (
    <Link to={`/video/${id}`} data-diff={video.difficulty.toLowerCase()} data-cat={video.category.toLowerCase()} className={isHidden ? "d-none" : null}>
      <div className="row align-items-center">
        <div className="col-lg-6">
          {/* <img className="img-fluid" src="http://placehold.it/690x300" /> */}
          <img className="img-fluid" src={`https://img.youtube.com/vi/${youtubeId}/maxresdefault.jpg`} alt="youtube video banner" />
        </div>
        <div className="col-lg-2 col-4">
          <p>
            <strong>{title}</strong>
          </p>
        </div>
        <div className="col-lg-2 col-4">
          <p>{artist}</p>
        </div>
        <div className="col-lg-2 col-4">
          <p>{difficulty}</p>
        </div>
      </div>
    </Link>
  );
};

const VideoList = ({ videos, activeCats, activeDifficulty }) => {
  if (!videos) return <h3>Fetching Videos</h3>;
  const sortedVids = videos.sort(function (a, b) {
    var nameA = a.title.toUpperCase(); // ignore upper and lowercase
    var nameB = b.title.toUpperCase(); // ignore upper and lowercase
    if (nameA < nameB) {
      return -1;
    }
    if (nameA > nameB) {
      return 1;
    }

    // names must be equal
    return 0;
  });

  return (
    <div id="songList">
      {sortedVids.map(video => {
        const isActiveCat = activeCats && activeCats.includes(video.category.toUpperCase());
        const isActiveLevel = activeDifficulty && activeDifficulty.includes(video.difficulty.toUpperCase());
        const isHidden = !isActiveCat || !isActiveLevel;
        return <VideoRow video={video} key={video.id} isHidden={isHidden} />;
      })}
    </div>
  );
};

const Library = props => {
  const categories = ["BLUES", "ROCK", "ACOUSTIC", "COUNTRY", "THEORY"];
  const difficulties = ["BEGINNER", "EXPERIENCED"];
  const [activeCats, setActiveCats] = useState(categories);
  const [activeDifficulty, setActiveLevel] = useState(difficulties);
  const [searchResults, setSearchResults] = useState([]);

  function handleUpdateDiffculty(e) {
    const difficulty = e.target.innerText.toUpperCase();
    e.target.classList.toggle("bg-secondary");
    if (activeDifficulty.includes(difficulty)) {
      let newState = activeDifficulty.filter(level => {
        return level !== difficulty;
      });
      setActiveLevel(newState);
    } else {
      let newState = activeDifficulty.concat(difficulty);
      setActiveLevel(newState);
    }
    // e.target.classList.toggle('bg-secondary');
    // let videosNodeList = document.querySelectorAll(`[data-diff=${level}]`);
    // videosNodeList.forEach(node => node.classList.toggle('d-none'));
  }
  function handleUpdateCats(e) {
    // const category = e.target.innerText.toUpperCase();
    const category = e.target.dataset.category;
    e.target.classList.toggle("bg-secondary");
    if (activeCats.includes(category)) {
      let newState = activeCats.filter(cat => {
        return cat !== category;
      });
      setActiveCats(newState);
    } else {
      let newState = activeCats.concat(category);
      setActiveCats(newState);
    }
  }
  async function handleSearch(e) {
    // make sure it isn't blank
    if (e.target.value.trim() === "") {
      setSearchResults([]);
      return;
    }
    // make sure its longer than one char
    if (e.target.value.trim().length < 2) {
      return;
    }
    const searchTerm = e.target.value;
    const search = { filter: { description: { contains: searchTerm } }, limit: 1000 };
    const result = await API.graphql(graphqlOperation(queries.listVideos, search));
    setSearchResults(result.data.listVideos.items);
    // console.log(searchTerm, result.data.listVideos.items);
  }

  return (
    <Connect query={graphqlOperation(queries.listVideos, { limit: 100 })}>
      {({ data: { listVideos }, loading, errors }) => {
        if (errors.length > 0) return <h3>Error</h3>;
        // if (loading || !listVideos) return <h3>Loading...</h3>;
        const videos = listVideos ? listVideos.items : undefined;
        return (
          <>
            <div className="container-fluid" id="topBG">
              <header className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6">
                    <h1>LIBRARY</h1>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-lg-10">
                    <iframe
                      width="560"
                      height="315"
                    //   src="https://www.youtube.com/embed/aEpHRyPAVEM"
                      src="https://www.youtube.com/embed/yhRA-cjkffk"
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                    />
                  </div>
                </div>
              </header>
            </div>
            {/* <div className="container-fluid" id="topBG"> */}
            {/*   <header className="container"> */}
            {/*     <div className="row justify-content-center"> */}
            {/*       <div className="col-md-6"> */}
            {/*         <h1>LIBRARY</h1> */}
            {/*         <hr /> */}
            {/*       </div> */}
            {/*     </div> */}
            {/*   </header> */}
            {/* </div> */}
            <div className="container">
              <div className="row">
                <div className="col-12">
                  <div className="input-group mt-3 mb-3">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="basic-addon1">
                        Search
                      </span>
                    </div>
                    <input type="text" className="form-control" placeholder="Blues" aria-label="Search" aria-describedby="basic-addon1" onChange={handleSearch} />
                    {searchResults.length > 0 && (
                      <div className="col-12">
                        <h4>Results</h4>
                        <ul>
                          {searchResults.map((result, index) => {
                            if (index > 10) {
                              return;
                            }
                            return (
                              <li key={result.id}>
                                <Link to={`/video/${result.id}`}>{result.title}</Link>
                              </li>
                            );
                          })}
                        </ul>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row justify-content-center">
                {difficulties.map(diff => {
                  return (
                    <div className="col-4" key={diff}>
                      <button type="button" className="btn" onClick={handleUpdateDiffculty}>
                        {diff}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div className="row justify-content-center">
                <div className="col-md-6">
                  <h3>CATEGORIES</h3>
                </div>
              </div>
              <div className="row justify-content-center" id="categories">
                {categories.map(category => {
                  return (
                    <div className="col" key={category}>
                      <button type="button" className="btn" onClick={handleUpdateCats} data-category={category}>
                        {category === "THEORY" ? "THEORY & TECHNIQUE" : category}
                      </button>
                    </div>
                  );
                })}
              </div>
              <div id="listHeader" className="d-none d-lg-block">
                <div className="row">
                  <div className="col-6">
                    <p>VIDEO</p>
                  </div>
                  <div className="col-2">
                    <p>TITLE</p>
                  </div>
                  <div className="col-2">
                    <p>ARTIST</p>
                  </div>
                  <div className="col-2">
                    <p>DIFFICULTY</p>
                  </div>
                </div>
              </div>
              <VideoList videos={videos} activeCats={activeCats} activeDifficulty={activeDifficulty} />
            </div>
          </>
        );
      }}
    </Connect>
  );
};

export default Library;
