import React, { useState, useEffect, useContext } from "react";
import createAuth0Client from "@auth0/auth0-spa-js";
import { API, graphqlOperation } from "aws-amplify";
import axios from "axios";
import * as Sentry from "@sentry/browser";
import * as queries from "./graphql/queries";
import * as mutations from "./graphql/mutations";

const getIdFromAuth0 = sub => {
  if (!sub) return;
  return sub.split("|")[1];
};

const createBTUser = async email => {
  const url = `${process.env.REACT_APP_NETLIFY_API}/btcreateuser`;
  const options = {
    url,
    method: "POST",
    data: {
      email
    }
  };
  const res = await axios(options).then(res => {
    return res.data;
  });
  return res;
};

const getBTSub = async id => {
  const url = `${process.env.REACT_APP_NETLIFY_API}/btgetsub`;
  const options = {
    url,
    method: "POST",
    data: {
      id
    }
  };
  const res = await axios(options).then(res => {
    return res.data;
  });
  const isActive = res.status === "Active";
  return isActive;
};

// const getBTUser = async id => {
//   const url = `${process.env.REACT_APP_NETLIFY_API}/btgetuser`;
//   const options = {
//     url,
//     method: 'POST',
//     data: {
//       id,
//     },
//   };
//   const res = await axios(options).then(res => {
//     return res.data;
//   });
//   return res;
// };

const DEFAULT_REDIRECT_CALLBACK = () => window.history.replaceState({}, document.title, window.location.pathname);

export const Auth0Context = React.createContext();
export const useAuth0 = () => useContext(Auth0Context);
export const Auth0Provider = ({ children, onRedirectCallback = DEFAULT_REDIRECT_CALLBACK, ...initOptions }) => {
  const [isAuthenticated, setIsAuthenticated] = useState();
  const [user, setUser] = useState();
  const [userMetaData, setUserMetaData] = useState();
  const [auth0Client, setAuth0] = useState();
  const [loading, setLoading] = useState(true);
  const [popupOpen, setPopupOpen] = useState(false);

  async function initAuth0() {
    const auth0FromHook = await createAuth0Client(initOptions);
    setAuth0(auth0FromHook);

    if (window.location.search.includes("code=") && window.location.search.includes("state=")) {
      const { appState } = await auth0FromHook.handleRedirectCallback();
      onRedirectCallback(appState);
    }

    const isAuthenticated = await auth0FromHook.isAuthenticated();

    setIsAuthenticated(isAuthenticated);

    if (isAuthenticated) {
      const user = await auth0FromHook.getUser();
      const auth0UserId = getIdFromAuth0(user.sub);
      Sentry.configureScope(function (scope) {
        scope.setUser({ email: user.email });
      });
      let userMetaData = {};
      try {
        // get data from the DB
        const awsUsersQuery = await API.graphql(
          graphqlOperation(queries.getUserByAuthO, { auth0: auth0UserId })
        );
        const userData = awsUsersQuery.data.getUserByAuthO.items;
        console.log("react-auto0-spa userData", userData)
        // if there is no user, create one
        if (!userData || userData.length === 0) {
          // create BT User
          const BTUser = await createBTUser(user.email);
          const newUser = {
            auth0: auth0UserId,
            braintreeId: BTUser.id,
            email: user.email,
            role: "VIEWER"
          };

          const createUser = await API.graphql(
            graphqlOperation(mutations.createUser, {
              input: newUser
            })
          );
          const newUserMeta = createUser.data.createUser;
          userMetaData = newUserMeta;
          setUserMetaData(userMetaData);
        } else {
          userMetaData = userData[0];
          // should check if there is no BT User
          const subStatus = await getBTSub(userMetaData.btSubId);
          userMetaData.activeSub = subStatus;
          console.log("react-auto0-spa subStatus", subStatus)
          setUserMetaData(userMetaData);
        }
        // add metadata
        user.appMetaData = userMetaData;
        setUser(user);
        setUserMetaData(userMetaData);
      } catch (err) {
        console.error(err);
      }
    }
    setLoading(false);
  };
  // eslint-disable-next-line

  useEffect(() => {
    initAuth0();
  }, []);

  const loginWithPopup = async (params = {}) => {
    setPopupOpen(true);
    try {
      await auth0Client.loginWithPopup(params);
    } catch (error) {
      console.error(error);
    } finally {
      setPopupOpen(false);
    }
    const user = await auth0Client.getUser();
    // get other user data
    // set user data
    setUser(user);
    setIsAuthenticated(true);
  };

  const handleRedirectCallback = async () => {
    setLoading(true);
    await auth0Client.handleRedirectCallback();
    const user = await auth0Client.getUser();
    // get other user data

    // set user data
    setLoading(false);
    setIsAuthenticated(true);

    setUser(user);
  };
  return (
    <Auth0Context.Provider
      value={{
        isAuthenticated,
        user,
        userMetaData,
        setUserMetaData,
        loading,
        popupOpen,
        loginWithPopup,
        handleRedirectCallback,
        getIdTokenClaims: (...p) => auth0Client.getIdTokenClaims(...p),
        loginWithRedirect: (...p) => auth0Client.loginWithRedirect(...p),
        getTokenSilently: (...p) => auth0Client.getTokenSilently(...p),
        getTokenWithPopup: (...p) => auth0Client.getTokenWithPopup(...p),
        logout: (...p) => auth0Client.logout(...p)
      }}
    >
      {children}
    </Auth0Context.Provider>
  );
};
