import React, { useState, useEffect } from "react";
import { Connect } from "aws-amplify-react";
import { API, graphqlOperation } from "aws-amplify";
import { useAuth0 } from "../react-auth0-spa";
import * as queries from "../graphql/queries";
import * as mutations from "../graphql/mutations";
import brandon from "../images/new-brandon.jpg";
import playing from "../images/new-playing.jpg";

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const encode = data => {
    return Object.keys(data)
      .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
      .join("&");
  };

  const handleSubmit = e => {
    const submission = { name, email, message };
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({ "form-name": "contact", ...submission })
    })
      .then(() => alert("Success!"))
      .catch(error => alert(error));

    e.preventDefault();
  };
  return (
    <form name="contact" method="post" onSubmit={handleSubmit}>
      <input type="hidden" name="form-name" value="contact" />
      <div className="row justify-content-center">
        <div className="col-md-6">
          <h2>CONTACT ME</h2>
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10 form-group">
          <label>Your name*</label>
          <input className="form-control" type="text" name="name" placeholder="Rock Star" value={name} onChange={e => setName(e.target.value)} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10 form-group">
          <label>Your email address*</label>
          <input className="form-control" type="email" name="email" placeholder="you@example.com" value={email} onChange={e => setEmail(e.target.value)} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-lg-10 form-group">
          <label>Whats on your mind?*</label>
          <textarea className="form-control" name="message" value={message} onChange={e => setMessage(e.target.value)} />
        </div>
      </div>
      <div className="row justify-content-center">
        <div className="col-md-6">
          <button type="submit" className="btn">
            SUBMIT
          </button>
        </div>
      </div>
    </form>
  );
};

const VideoCarousel = ({ videos }) => {
  const [activeSlide, setActiveSlide] = useState(0);
  if (videos.length === 0) return <p>Loading...</p>;

  function incrementSlide(e) {
    e.preventDefault();
    if (activeSlide === videos.length - 1) {
      setActiveSlide(0);
    } else {
      setActiveSlide(activeSlide + 1);
    }
  }
  function decrementSlide(e) {
    e.preventDefault();
    if (activeSlide === 0) {
      setActiveSlide(videos.length - 1);
    } else {
      setActiveSlide(activeSlide - 1);
    }
  }

  return (
    <div id="carouselExampleControls" className="carousel slide" data-ride="carousel">
      <div className="carousel-inner">
        {videos.map((video, index) => {
          if (video.length === 0) return;
          return (
            <div className={`carousel-item ${index === activeSlide ? "active" : ""}`} key={video}>
              <iframe
                width="560"
                height="315"
                src={`https://www.youtube.com/embed/${video}`}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </div>
          );
        })}
      </div>
      <a className="carousel-control-prev" href="#carouselExampleControls" role="button" data-slide="prev" onClick={decrementSlide}>
        <span className="carousel-control-prev-icon" aria-hidden="true" />
        <span className="sr-only">Previous</span>
      </a>
      <a className="carousel-control-next" href="#carouselExampleControls" role="button" data-slide="next" onClick={incrementSlide}>
        <span className="carousel-control-next-icon" aria-hidden="true" />
        <span className="sr-only">Next</span>
      </a>
    </div>
  );
};

const Home = () => {
  const { loginWithRedirect } = useAuth0();
  const [appMetaData, setAppMetaData] = useState({ carouselVideos: [] });

  useEffect(() => {
    async function getData() {
      const data = await API.graphql(graphqlOperation(queries.listAppMetaDatas));
      setAppMetaData(data.data.listAppMetaDatas.items[0]);
    }
    getData();
  }, []);
  const bannerVideoSrc = `https://www.youtube.com/embed/${appMetaData.bannerVideo}?rel=0`;
  return (
    <>
      <div className="container-fluid" id="topBG">
        <header className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h1>
                GUITAR LESSONS WITH BRANDON PAUL<br />
                <hr />
                {/* <span id="topName">WITH BRANDON PAUL</span> */}
              </h1>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-lg-10">
              {appMetaData.bannerVideo && (
                <iframe width="560" height="315" src={bannerVideoSrc} frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen />
              )}
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <button type="button" className="btn" onClick={() => loginWithRedirect({})}>
                GET STARTED
              </button>
              <h3>
                HELPING YOU CONQUER <br /> THE GUITAR AND MUSICAL GOALS
              </h3>
            </div>
          </div>
        </header>
      </div>
      <div className="container-fluid bgGray">
        <div className="container">
          <div className="row smFlip">
            <div className="col-md-6">
              <img className="img-fluid" src={brandon} />
            </div>
            <div className="col-md-6">
              <h2>
                <span className="lightType">MEET</span> BRANDON
              </h2>
              <hr />
              <p>
                A hard working, inspiring music enthusiast- Brandon is not your average guitar teacher. Having taught at multiple music schools, a bestselling rock course on Udemy and with over a decade of private guitar instruction under his belt, you can feel easy knowing you're learning from a seasoned pro. Brandon also demonstrates his teachings through live performance and touring- with Lizzy Borden, Icons Of Classic Rock, Bobby Kimball of Toto, Frankie Grande &amp; so many more.

                {/* A hard working, inspiring music enthusiast- Brandon is not your average guitar teacher. Having taught at multiple music schools and with over a decade of private guitar instruction
                under his belt, you can feel easy knowing you’re learning from a true experienced pro! Brandon also demonstrates his accolades of teaching through live performance and touring-
                alongside the likes of Slash, Bobby Kimball of Toto, Ratt, Extreme &amp; so many more. The amalgamation of teaching, touring, composing &amp; more is what the Guitar Alliance has
                manifested as! */}
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="howItWorks" className="navOffset" />
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <h2>
                <span className="lightType">HOW</span> IT WORKS
              </h2>
              <hr />
              <p>
                Simple to follow along videos with Brandon make it easy for you to learn many styles, countless techniques, tons of songs &amp; so much more. You can learn from any device at any time- smartphone, computer, iPad &amp; more. Learning to play from the convenience of your home couldn’t be any easier.

                {/* Learning the guitar should be fun and easy, not boring and painstaking. Simple follow along videos with Brandon make it easy for you to learn many styles, countless techniques, tons of
                songs &amp; so much more. And if you’re a beginner here at the Alliance, you will be making music &amp; playing some of your favorite songs right away! No more scrolling through
                Youtube videos for help; the Guitar Alliance is here to show you the way. */}
                {" "}
              </p>
              <button type="button" className="btn" onClick={() => loginWithRedirect({})}>
                START LEARNING NOW
              </button>
            </div>
            <div className="col-md-6">
              <img className="img-fluid" src={playing} />
            </div>
          </div>
        </div>
      </div>
      <div id="reviews" className="navOffset" />
      <div className="container-fluid bgGray noSpacer">
        <div className="container">
          <div className="row">
            <div className="col-lg-6">
              <h2>
                <span className="lightType">GETTING</span> STARTED
              </h2>
              <hr />
              <p>
                It is very important that you learn from someone who demonstrates their teachings and has the results in their own career. There is a lot of depth &amp; wisdom that goes into the teachings at that point- and this is what will benefit you most when learning. And whether you’ve never held a guitar before or you are looking to sharpen your advanced skills- these lessons will accommodate you. Brandon welcomes all ages, styles &amp; skill levels. And to top it all off, you’ll get a free 7 day trial to start.

                {/* Ask yourself this: would you rather learn from a random teacher, or from someone who can demonstrate their teachings and success in their own music career? Whether you’ve never held a
                guitar before or you are looking to sharpen your advanced skills- you’ve found the perfect place. The Guitar Alliance welcomes all ages, styles & skill levels! */}
                {" "}
              </p>
            </div>
            <div className="col-lg-2 quote quoteWhiteBox d-lg-none">
              <h4>WHAT PEOPLE ARE SAYING</h4>
            </div>
            <div className="col-lg-2 quote" id="quoteBg1">
              <p>
                “Within just a short time I learned more than I could in a year! I absolutely recommend Brandon’s lessons to all!” <span className="blueQuote">Nicholas B.</span>
              </p>
            </div>
            <div className="col-lg-2 quote quoteWhiteBox d-none d-lg-block">
              <h4>WHAT PEOPLE ARE SAYING</h4>
            </div>
            <div className="col-lg-2 quote" id="quoteBg2">
              <p>
                “Brandon is a world-class guitar player and teacher, and with outstanding musicianship & teaching skills its almost impossible to miss such a one-of-a-kind musician.”{" "}
                <span className="blueQuote">Lee P.</span>
              </p>
            </div>
          </div>
          <div className="row mdFlip">
            <div className="col-lg-6">
              <h2>
                <span className="lightType">MORE ON</span> BRANDON
              </h2>
              <hr />
              <p>
                10’s of millions of views across social media, a bestselling rock course on Udemy, loads of testimonials/reviews & 100’s of thousands of followers online. Everyone’s support has gone into the creation of these lessons!

                {/* When you join the Guitar Alliance you aren’t just getting top-level pro lessons; you are now a part of a community! Becoming a member instantly also adds you to the Facebook Alliance
                group; where you have even MORE support & help from other players & students just like you! */}
                {" "}
              </p>
            </div>
            <div className="col-lg-2 quote" id="quoteBg3">
              <p>
                “Brandon is brilliant in what he does. The coolest guy and very inspirational! I highly recommend him for anyone who wants to improve or learn the guitar!”{" "}
                <span className="blueQuote">Zilvinas P.</span> 
              </p>
            </div>
            <div className="col-lg-2 quote" id="quoteBg4">
              <p>
                “I was self taught, realized I had developed a lot of bad habits and needed professional instruction. Brandon has been a savior and my playing has improved tremendously!”{" "}
                <span className="blueQuote">Jon T.</span>
              </p>
            </div>
            <div className="col-lg-2 quote" id="quoteBg5">
              <p>
                “Brandon is an awesome guitar instructor. He’s wickedly talented, has great teaching methods and most of all makes his lessons fun.” <span className="blueQuote">Doug M.</span>
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="songs" className="navOffset" />
      <div className="container-fluid">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10">
              <VideoCarousel videos={appMetaData.carouselVideos} />
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-6">
              <button type="button" className="btn" onClick={() => loginWithRedirect({})}>
                GET FULL ACCESS
              </button>
            </div>
          </div>
          <div id="contact" className="navOffset" />
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Home;
