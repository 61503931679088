// src/index.js
import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/browser";
import LogRocket from "logrocket";
import "bootstrap/dist/css/bootstrap.css";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { Auth0Provider } from "./react-auth0-spa";
// import config from './auth_config.json';
import history from "./utils/history";

const { REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_REDIRECT_URI, REACT_APP_SENTRY_DSN, NODE_ENV } = process.env;
Sentry.init({ dsn: REACT_APP_SENTRY_DSN, environment: NODE_ENV });

// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(appState && appState.targetUrl ? appState.targetUrl : window.location.pathname);
};

LogRocket.init("uerju4/brandon-paul");

ReactDOM.render(
  <Auth0Provider domain={REACT_APP_AUTH0_DOMAIN} client_id={REACT_APP_AUTH0_CLIENT_ID} redirect_uri={REACT_APP_AUTH0_REDIRECT_URI} onRedirectCallback={onRedirectCallback}>
    <App />
  </Auth0Provider>,
  document.getElementById("root")
);

serviceWorker.unregister();
