/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getVideo = `query GetVideo($id: ID!) {
  getVideo(id: $id) {
    id
    youtubeId
    title
    artist
    difficulty
    description
    category
    order
  }
}
`;
export const listVideos = `query ListVideos(
  $filter: ModelVideoFilterInput
  $limit: Int
  $nextToken: String
) {
  listVideos(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      youtubeId
      title
      artist
      difficulty
      description
      category
      order
    }
    nextToken
  }
}
`;
export const getUser = `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    auth0
    email
    braintreeId
    btSubId
    role
  }
}
`;
export const listUsers = `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      auth0
      email
      braintreeId
      btSubId
      role
    }
    nextToken
  }
}
`;
export const getAppMetaData = `query GetAppMetaData($id: ID!) {
  getAppMetaData(id: $id) {
    id
    bannerVideo
    carouselVideos
  }
}
`;
export const listAppMetaDatas = `query ListAppMetaDatas(
  $filter: ModelAppMetaDataFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppMetaDatas(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      bannerVideo
      carouselVideos
    }
    nextToken
  }
}
`;
export const getUserByAuthO = `query GetUserByAuthO(
  $auth0: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByAuthO(
    auth0: $auth0
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      auth0
      email
      braintreeId
      btSubId
      role
    }
    nextToken
  }
}
`;
