import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { API, graphqlOperation } from "aws-amplify";
import DropIn from "braintree-web-drop-in-react";
import axios from "axios";
import { useAuth0 } from "../react-auth0-spa";
import * as mutations from "../graphql/mutations";

const Payment = () => {
  let history = useHistory();
  const { user, userMetaData, setUserMetaData } = useAuth0();
  const [clientToken, setClientToken] = useState();
  const [loading, setLoading] = useState(true);
  const [instance, setInstance] = useState();
  const braintreeId = user && userMetaData.braintreeId;

  const getToken = async () => {
    const url = `${process.env.REACT_APP_NETLIFY_API}/braintree`;
    const options = {
      url,
      method: "POST",
      data: {
        braintreeId
      }
    };
    await axios(options).then(res => {
      setClientToken(res.data);
    });
    setLoading(false);
  };

  const createSub = async token => {
    const url = `${process.env.REACT_APP_NETLIFY_API}/btcreatesub`;
    const options = {
      url,
      method: "POST",
      data: {
        customerId: braintreeId,
        token
      }
    };
    return await axios(options).then(res => {
      return res;
    });
  };
  const updateUser = async btSubId => {
    // I need the amplify id
    const update = { id: userMetaData.id, btSubId };
    return await API.graphql(graphqlOperation(mutations.updateUser, { input: update }));
  };

  useEffect(() => {
    getToken().catch(err => {
      window.alert("A Server Error Occured.");
    });
  }, []);

  const buy = async () => {
    setLoading(true);
    const { nonce } = await instance.requestPaymentMethod();
    // do something with the nonce
    const newSub = await createSub(nonce);
    const subId = newSub.data.id;
    await updateUser(subId).catch(err => {
      console.error(err);
      setLoading(false);
      return;
    });
    // update the local state as well
    setUserMetaData({ ...userMetaData, btSubId: subId, activeSub: true });
    setLoading(false);
    history.push("/library");
  };

  return (
    <>
      <div className="container-fluid" id="topBG">
        <header className="container">
          <div className="row justify-content-center">
            <div className="col-md-6">
              <h1>UPDATE PAYMENT</h1>
              <hr />
            </div>
          </div>
        </header>
      </div>
      <div className="container-fluid">
        <div className="container">
          <div className="row">
            {clientToken && (
              <div className="col-md-12">
                <DropIn options={{ authorization: clientToken }} onInstance={instance => setInstance(instance)} />
                <button disabled={loading} className="btn" onClick={() => buy()}>
                  Buy
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
