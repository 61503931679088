/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createVideo = `mutation CreateVideo(
  $input: CreateVideoInput!
  $condition: ModelVideoConditionInput
) {
  createVideo(input: $input, condition: $condition) {
    id
    youtubeId
    title
    artist
    difficulty
    description
    category
    order
  }
}
`;
export const updateVideo = `mutation UpdateVideo(
  $input: UpdateVideoInput!
  $condition: ModelVideoConditionInput
) {
  updateVideo(input: $input, condition: $condition) {
    id
    youtubeId
    title
    artist
    difficulty
    description
    category
    order
  }
}
`;
export const deleteVideo = `mutation DeleteVideo(
  $input: DeleteVideoInput!
  $condition: ModelVideoConditionInput
) {
  deleteVideo(input: $input, condition: $condition) {
    id
    youtubeId
    title
    artist
    difficulty
    description
    category
    order
  }
}
`;
export const createUser = `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    auth0
    email
    braintreeId
    btSubId
    role
  }
}
`;
export const updateUser = `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    auth0
    email
    braintreeId
    btSubId
    role
  }
}
`;
export const deleteUser = `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    auth0
    email
    braintreeId
    btSubId
    role
  }
}
`;
export const createAppMetaData = `mutation CreateAppMetaData(
  $input: CreateAppMetaDataInput!
  $condition: ModelAppMetaDataConditionInput
) {
  createAppMetaData(input: $input, condition: $condition) {
    id
    bannerVideo
    carouselVideos
  }
}
`;
export const updateAppMetaData = `mutation UpdateAppMetaData(
  $input: UpdateAppMetaDataInput!
  $condition: ModelAppMetaDataConditionInput
) {
  updateAppMetaData(input: $input, condition: $condition) {
    id
    bannerVideo
    carouselVideos
  }
}
`;
export const deleteAppMetaData = `mutation DeleteAppMetaData(
  $input: DeleteAppMetaDataInput!
  $condition: ModelAppMetaDataConditionInput
) {
  deleteAppMetaData(input: $input, condition: $condition) {
    id
    bannerVideo
    carouselVideos
  }
}
`;
