import React, { useState, useEffect } from "react";
import { Router, Switch, Route, Link, useRouteMatch } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useAuth0 } from "./react-auth0-spa";
import Amplify from "aws-amplify";
// import * as queries from "./graphql/queries";
// import logo from './logo.svg';
// import './App.css';
import Home from "./pages/Home";
import Library from "./pages/Library";
import Editor from "./pages/Editor";
import Video from "./pages/Video";
import Payment from "./pages/Payment";
import Admin from "./pages/Admin";
import Signup from "./pages/Signup";
// import Profile from './components/Profile';
import Profile from "./pages/Profile";
import history from "./utils/history";
import PrivateRoute from "./components/PrivateRoute";

import bpLogo from "./images/bpLogo.svg";
import socialFace from "./images/social-face.png";
import socialInsta from "./images/social-insta.png";
import socialTube from "./images/social-tube.png";
import navWitch from "./images/navwitch.svg";
// import awsconfig from './aws-exports';

let awsconfig = {
  aws_project_region: process.env.REACT_APP_AWS_PROJECT_REGION,
  aws_appsync_graphqlEndpoint: process.env.REACT_APP_AWS_APPSYNC_GRAPHQLENDPOINT,
  aws_appsync_region: process.env.REACT_APP_AWS_APPSYNC_REGION,
  aws_appsync_authenticationType: process.env.REACT_APP_AWS_APPSYNC_AUTHENTICATIONTYPE,
  aws_appsync_apiKey: process.env.REACT_APP_AWS_APPSYNC_APIKEY
};

Amplify.configure(awsconfig);

const HomeNav = ({ smoothScroll }) => {
  const { isAuthenticated } = useAuth0();
  return (
    <>
      <div className="col noPadding d-none d-lg-block">
        <a href="#howItWorks" className="navLink" onClick={smoothScroll}>
          HOW IT WORKS
        </a>
      </div>
      <div className="col noPadding d-none d-lg-block">
        <a href="#reviews" className="navLink" onClick={smoothScroll}>
          REVIEWS
        </a>
      </div>
      <div className="col noPadding d-none d-lg-block" onClick={smoothScroll}>
        <a href="#songs" className="navLink">
          SONGS
        </a>
      </div>
      <div className="col noPadding d-none d-lg-block" onClick={smoothScroll}>
        <a href="#contact" className="navLink">
          ASK BRANDON
        </a>
      </div>
      {isAuthenticated && (
        <div className="col noPadding d-none d-lg-block">
          <Link to="/library" className="navLink">
            LIBRARY
          </Link>
        </div>
      )}
    </>
  );
};

const NotHomeNav = props => {
  const { user, userMetaData } = useAuth0();
  let isAdmin = false;
  if (user) {
    isAdmin = userMetaData.role === "ADMIN";
  }

  return (
    <>
      <div className="col-lg-1 col-2 d-none d-lg-block noPadding">
        <Link to="/profile" className="navLink">
          PROFILE
        </Link>
      </div>
      {isAdmin && (
        <>
          <div className="col-lg-1 col-2 d-none d-lg-block noPadding">
            <Link to="/editor" className="navLink">
              EDITOR
            </Link>
          </div>
          <div className="col-lg-1 col-2 d-none d-lg-block noPadding">
            <Link to="/admin" className="navLink">
              ADMIN
            </Link>
          </div>
        </>
      )}
      <div className="col-lg-1 col-2 d-none d-lg-block noPadding">
        <Link to="/library" className="navLink">
          LIBRARY
        </Link>
      </div>
    </>
  );
};

const Nav = props => {
  const { loading, userMetaData, isAuthenticated, loginWithRedirect, logout } = useAuth0();
  const { isExact: atRootURL } = useRouteMatch("/");
  const [navOpen, setNavOpen] = useState(false);
  let isAdmin = false;
  if (userMetaData) {
    isAdmin = userMetaData.role === "ADMIN";
  }

  const smoothScroll = e => {
    e.preventDefault();
    // const { href } = e.target;
    const el = document.querySelector(e.target.hash);
    el.scrollIntoView({ behavior: "smooth" });
    // this.scrollTo({ behavior: 'smooth' });
  };

  useEffect(() => {});
  // if (atRootURL) return <p></p>;
  return (
    <nav>
      <div className="container">
        <div className="row">
          <div className="col-4 d-lg-none">
            <div className={`nav-item dropdown ${navOpen ? "show" : null}`}>
              <a className="nav-link dropdown-toggle" data-toggle="dropdown" role="button" onClick={() => setNavOpen(!navOpen)} aria-haspopup="true" aria-expanded="false">
                <img src={navWitch} alt="hamburger menu bar" />
              </a>
              <div className={`dropdown-menu ${navOpen ? "show" : null}`}>
                {atRootURL && (
                  <>
                    <a href="#howItWorks" onClick={smoothScroll}>
                      HOW IT WORKS
                    </a>
                    <a href="#reviews" onClick={smoothScroll}>
                      REVIEWS
                    </a>
                    <a href="#songs" onClick={smoothScroll}>
                      SONGS
                    </a>
                    <a href="#contact" onClick={smoothScroll}>
                      ASK BRANDON
                    </a>
                  </>
                )}
                {isAuthenticated && (
                  <>
                    <Link to="/profile" className="navLink">
                      PROFILE
                    </Link>
                    {isAdmin && (
                      <Link to="/editor" className="navLink">
                        EDITOR
                      </Link>
                    )}

                    <Link to="/library" className="navLink">
                      LIBRARY
                    </Link>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="col-4 col-lg-2 noPadding">
            <Link to="/">
              <img className="img-fluid" id="topLogo" src={bpLogo} alt="Brandon Paul" />
            </Link>
          </div>
          {atRootURL && <HomeNav smoothScroll={smoothScroll} />}
          {!atRootURL && <NotHomeNav />}

          <div className="col-lg col-4">
            {!isAuthenticated && (
              <button className="navLink" id="logIn" onClick={() => loginWithRedirect({})} disabled={loading}>
                Log in
              </button>
            )}

            {isAuthenticated && (
              <button
                className="navLink"
                id="logIn"
                onClick={() =>
                  logout({
                    returnTo: window.location.origin
                  })
                }
              >
                Log out
              </button>
            )}
          </div>
          <div className="col-2 noPadding d-none d-lg-block">
            <a href="https://www.facebook.com/brandonpaulofficial/" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialFace} alt="facebook logo" />
            </a>
            <a href="https://www.instagram.com/brandonpaulofficial/" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialInsta} alt="instagram logo" />
            </a>
            <a href="https://www.youtube.com/brandonpaul89" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialTube} alt="youtube logo" />
            </a>
          </div>
        </div>
      </div>
    </nav>
  );
};

const Footer = props => {
  return (
    <footer className="container-fluid noSpacer">
      <div className="container">
        <div className="row">
          <div className="col">
            <h3>
              BRINGING OUT YOUR <strong>CREATION</strong> AND <strong>TALENTS</strong>
            </h3>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <a href="https://www.facebook.com/brandonpaulofficial/" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialFace} alt="facebook logo" />
            </a>
            <a href="https://www.instagram.com/brandonpaulofficial/" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialInsta} alt="instagram logo" />
            </a>
            <a href="https://www.youtube.com/brandonpaul89" target="_blank" rel="noopener noreferrer">
              <img className="socialLink" src={socialTube} alt="youtube logo" />
            </a>
          </div>
        </div>
        <div className="row">
          <div className="col">
            <h4>
              Design and developed by{" "}
              <a href="http://4eigndesign.com/" target="_blank" rel="noopener noreferrer">
                4eignDesign
              </a>
            </h4>
            <p>All Rights Reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
};

const TempHome = () => {
  return <h1>Coming Soon</h1>;
};

function App() {
  // const { loading, isAuthenticated, loginWithRedirect, logout, user } = useAuth0();

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <link rel="icon" href="./images/favicon.ico" />
        <title>Brandon Paul</title>
        <meta name="description" content="Online Guitar Lessons | Learn Guitar with Brandon Paul" />
        <meta property="og:url" content="https://brandonpaulguitaralliance.com/" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content="Brandon Paul Guitar Alliance" />
        <meta property="og:description" content="Online Guitar Lessons | Learn Guitar with Brandon Paul" />
        <meta property="og:image" content={bpLogo} />
      </Helmet>
      <Router history={history}>
        <Nav />
        <Switch>
          <Route path="/" exact>
            <Home />
            {/* <TempHome /> */}
          </Route>
          <PrivateRoute path="/profile" component={Profile} />
          {/* 
          1. check if logged in
          2. check if valid subscription
          */}
          <PrivateRoute path="/library" component={Library} subReq={true} />
          <Route path="/signup" component={Signup} />
          <Route path="/payment" component={Payment} />
          <PrivateRoute path="/admin" component={Admin} adminReq={true} />
          {/* 
          1. check if logged in
          2. check if admin
          */}
          <PrivateRoute path="/editor" component={Editor} adminReq={true} />
          <PrivateRoute path="/profile" component={Profile} />
          <PrivateRoute path="/video/:id" children={<Video />} subReq={true} />
        </Switch>
      </Router>
      <Footer />
    </>
  );
}

export default App;
