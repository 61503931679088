import React, { useEffect } from "react";
import { Route } from "react-router-dom";
import { useAuth0 } from "../react-auth0-spa";
import { useHistory } from "react-router-dom";

const PrivateRoute = ({ component: Component, path, adminReq, subReq, ...rest }) => {
  let history = useHistory();
  const { loading, isAuthenticated, loginWithRedirect, user, userMetaData } = useAuth0();

  useEffect(() => {
    if (loading) {
      return;
    }
    if (!user) {
      history.push("/");
      return;
    }
    const role = userMetaData.role;
    // const BTId = user.appMetaData.braintreeId;
    // const isAdmin = role === 'ADMIN';
    const isAdmin = role === "ADMIN";
    const subscription = userMetaData.activeSub || false;
    if (adminReq && !isAdmin) {
      history.goBack();
      return;
    }
    if (isAdmin) {
      return;
    }
    if (subReq && !subscription) {
      history.push("/signup");
      return;
    }

    // const fn = async () => {
    //   await loginWithRedirect({
    //     appState: { targetUrl: path },
    //   });
    // };
    // fn();
  }, [loading, isAuthenticated, loginWithRedirect, path]);

  const render = props => (isAuthenticated === true ? <Component {...props} /> : null);

  return <Route path={path} render={render} {...rest} />;
};

export default PrivateRoute;
